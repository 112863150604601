import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {Typography,  CardActionArea} from '@mui/material';
import Container from '@mui/material/Container';

import { connect } from 'react-redux'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import Page404 from './Page404';


const View = ({user}) => {
  
  const [count, setCount] = React.useState(1);
  const [limit, setLimit] = React.useState(30);
  const [list, setList] = React.useState(null);
    const [{loading,loadingList,error}, setStatus] = React.useState({loading:true});
  
    //let navigate = useNavigate();
    


      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: baseAPIURL+'devices/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : {"limit":limit}
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);

    

    if(!user)
      return(<Page404/>)


    return (
            <main style={{padding:10}}>
              
              <Card fullWidth sx={{margin: 1 }}>
                    <CardContent>
                      
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} sx={{textAlign:"center"}}>
                  <img src='./images/usb_device.png' style={{ width:"100%", maxWidth:"180px", margin:"auto", marginTop:"20px"}}/>
                </Grid>
                <Grid item xs={12} md={9}>
                  <h2>USB Device</h2>
                  <p>This device is a USB powered WiFi Sound sensor.</p>
                  <p><strong>Cost $10 AUD per month</strong></p>
                  <p>10 in stock</p>




                  <TextField
                    id="outlined-number"
                    label="Qty"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={count}
                    onChange={(event)=>setCount(event.target.value>0 ? event.target.value : 1)}
                  />


<br/>
                  <br/>
                  
                  <Button   variant="contained">Get device</Button>

              
                </Grid>
              </Grid>

              
            </CardContent>

            </Card>

            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);