import React, {Component,useState  } from 'react'
import { NavLink,useNavigate  } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {Typography,  CardActionArea} from '@mui/material';
import Container from '@mui/material/Container';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { connect } from 'react-redux'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import Page404 from './Page404';
import Loader from "../components/other/Loader"


const View = ({user}) => {
  
    const [limit, setLimit] = React.useState(30);
    const [list, setList] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    const navigate = useNavigate();
    //let navigate = useNavigate();
    

    const HandleLoadMore = () => {

      const NewLimit = limit+30;
      setLimit(NewLimit)
      setStatus({loadingMore:true})
      firebase.auth().currentUser.getIdToken().then(function(idToken) {

          let data = JSON.stringify({
            "limit": NewLimit
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'devices/list',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });



    }



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "limit": limit
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'devices/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);

    

    
    const handleClick = (event, DeviceUid) => {
      console.log(DeviceUid)

      navigate("/devices/" + DeviceUid);
    }



    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)



    return (
      <main style={{padding:10}}>
      <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Device</TableCell>
                  <TableCell align="right">location</TableCell>
                  <TableCell align="right">Max (dBA)</TableCell>
                  <TableCell align="right">Min (dBA)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                

              {list &&  list.length>0 && list.map((row, index) => {
                return (

                <TableRow 
                  hover 
                  key={row.device_uuid} 
                  onClick={(event) => handleClick(event, row.device_uuid)}
                >
                <TableCell className='ErrorCell'>{row.alerts && <ErrorOutlineIcon/>}</TableCell>
                  <TableCell>{row.device_name ? row.device_name : row.device_uuid}</TableCell>
                  <TableCell align="right">{row.location? row.location :"Unknown"}</TableCell>
                  <TableCell align="right">{row.max? row.max+"dB" : null}</TableCell>
                  <TableCell align="right">{row.min? row.min+"dB" : null}</TableCell>
                </TableRow>
                )}
                )
              }



                  




              </TableBody>
            </Table>
          </TableContainer>


              <Box sx={{textAlign:"center",p:2}}> 
                  {loadingMore ? "Loading" : <Button onClick={HandleLoadMore}   variant="contained"> Load More</Button>}
              </Box>
        </main>
      );



/*
    return (
            <main>

<Container>
  <br/>
              


              

                <Grid container  spacing={2}>
                  {error ?
                    "Error"
                  :
                    list &&  list.length>0 ? list.map((row, index) => {
                        return (
                          <Grid item lg={2} md={3} sm={6} xs={12}  spacing={2}>
                            <Card   spacing={2}>
                              <CardActionArea  component={NavLink} to={"/datasets/"+row.data_id} >
                                
                                  <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                      {row.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    {row.details}
                        </Typography>
                                  </CardContent>
                                </CardActionArea>


                                {/ *<CardActions>
                                  <Button size="small" color="primary">
                                    Share
                                  </Button>
                                  <Button size="small" color="primary">
                                    Learn More
                                  </Button>
                                  <Button size="small" color="primary">
                                    Add Data
                                  </Button>
                        </CardActions>* /}

                              </Card>
                            </Grid>
                          );
                        })
                        :
                        !loading?
                        <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           You have no datasets.
                        </Typography>
                        </Box>
                        :null
                  }


            
{loading ? <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           Loading List
                        </Typography>
                        </Box>
                  : null}


                  {!loadingList && list &&  list.length>0 ?
                      <Grid item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                        <Button onClick={()=>{setLimit(limit+30); LoadData()}}>Load More</Button>
                      </Grid>
                    :
                      null
                  }
                    </Grid>
                    
              </Container>
            </main>
        );*/
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);