

var baseAPIURL = 'https://api.decibeldetect.com/';

/*
if (location.hostname === 'localhost') 
{*/
    //baseAPIURL = 'http://127.0.0.1:5001/blizo-70335/us-central1/blizoapi/';
//}
//var baseAPIURL = 'https://api.blizo.co/';


export { baseAPIURL };