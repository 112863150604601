import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
//import  Firebaseui  from 'firebaseui'
import { getAuth, connectAuthEmulator } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANatga4hMyJFIgsSgqNMmbuVF1GM4OlYk",
  authDomain: "decibel-detect.firebaseapp.com",
  projectId: "decibel-detect",
  storageBucket: "decibel-detect.appspot.com",
  messagingSenderId: "678920035796",
  appId: "1:678920035796:web:adf66195555d71478c4a41",
  measurementId: "G-X83R0XGS61"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
//firebase.storage();



//const db = firebase.firestore();
const auth = firebase.auth;

/*
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  const auth2 = getAuth();
  connectAuthEmulator(auth2,'http://localhost:9099/');//, { disableWarnings: true });
}*/

export default firebase;
export { /*db,*/ auth };