import React, {Component } from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/Box';



const Loader = (props) => {

    
        return <Box className="loadingView">
        {/*<CircularProgress  className="CircularProgress"/>*/}
        <h4>Loading</h4>

        <div className="loading-screen">
      <div className="loading-box">
        <div className="loading-lines">
          <div className="loading-line loading-line-1"></div>
          <div className="loading-line loading-line-2"></div>
          <div className="loading-line loading-line-3"></div>
          <div className="loading-line loading-line-4"></div>
          <div className="loading-line loading-line-5"></div>
          <div className="loading-line loading-line-6"></div>
          <div className="loading-line loading-line-7"></div>
        </div>
      </div>
    </div>
            
    </Box>
}

export default (Loader);