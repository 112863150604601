import React, {Component,useState  } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import firebase from 'firebase/compat/app'
import { NavLink,useNavigate  } from 'react-router-dom'

import {Table,Box} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'


import TimeAgo from 'react-timeago'

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const Index = ({user,}) => {

    const navigate = useNavigate();

    const list = [{name:"name1"},{name:"name2"},{name:"name3"},{name:"name4"},{name:"name5"}]

    
    


    
    const [activityList, setActivityList] = React.useState(30);
    const [activityStatus, setActivityStatus] = React.useState({loading:true});
    
    const [alertList, setAlertList] = React.useState(30);
    const [alertStatus, setAlertStatus] = React.useState({loading:true});
      


      const LoadData = () => {
        
          setActivityStatus({loading:true});
          setAlertStatus({loading:true});

          firebase.auth().currentUser.getIdToken().then(function(idToken) {
            


            let configActivity = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'devices/activity',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : JSON.stringify({})
            };
            
            axios.request(configActivity).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                  setActivityStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setActivityList(Result.data.list)
                    setActivityStatus({});
                }
                else
                {
                    setActivityList([])
                    setActivityStatus({error:true});
                }
              
            }).catch(err => {
                  console.error("handleSubmit")
                  console.error(err)
                  setActivityStatus({error:true});
            });


            


            let configAlert = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'alerts/active',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : JSON.stringify({})
            };
            
            axios.request(configAlert).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                  setAlertStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setAlertList(Result.data.list)
                    setAlertStatus({});
                }
                else
                {
                    setAlertList([])
                    setAlertStatus({error:true});
                }
              
            }).catch(err => {
              
                  console.error("handleSubmit")
                  console.error(err)
                  console.error(configAlert)
                  setAlertStatus({error:true});
            });






        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);

    



    const handleClick = (event, DeviceUid) => {
        console.log(DeviceUid)
  
        navigate("/devices/" + DeviceUid);
      }
  

        return (
            <main style={{padding:10}}>
                <Card fullWidth sx={{margin: 1 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                        Alerts
                        </Typography>
                        


                        <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Device</TableCell>
                  <TableCell align="right">Alert</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                

              {alertList &&  alertList.length>0 && alertList.map((row, index) => {
                return (

                <TableRow 
                  hover 
                  key={row.device_uuid} 
                  onClick={(event) => handleClick(event, row.device_uuid)}
                >
                  <TableCell className='ErrorCell'><ErrorOutlineIcon/></TableCell>
                  <TableCell>{row.device_name ? row.device_name : row.device_uuid}</TableCell>
                  <TableCell>{row.details}</TableCell>
                </TableRow>
                )}
                )
              }



              </TableBody>
            </Table>
          </TableContainer>

                    {alertStatus.loading? <Box sx={{textAlign:"center",p:1}}>Loading</Box>:null}
                    {alertStatus.error? <Box sx={{textAlign:"center",p:1}}>Error</Box>:null}

                    </CardContent>
                    <CardActions>
                    <Button  component={NavLink} to="/alerts"  size="small"  variant="contained">See More</Button>
                    </CardActions>
                    </Card>












                    <Card  sx={{ margin: 1,marginTop:3 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                        recent activity
                        </Typography>
                        


                        <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Device</TableCell>
                  <TableCell align="right">Last update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                

              {activityList &&  activityList.length>0 && activityList.map((row, index) => {
                return (

                <TableRow 
                  hover 
                  key={row.device_uuid} 
                  onClick={(event) => handleClick(event, row.device_uuid)}
                >
                <TableCell className='ErrorCell'>{row.alerts && <ErrorOutlineIcon/>}</TableCell>
                  <TableCell>{row.device_name ? row.device_name : row.device_uuid}</TableCell>
                  <TableCell align="right">{row.latest_timestamp ? <TimeAgo date={row.latest_timestamp}/> : null}</TableCell>
                </TableRow>
                )}
                )
              }



              </TableBody>
            </Table>
          </TableContainer>

                    {activityStatus.loading? <Box sx={{textAlign:"center",p:1}}>Loading</Box>:null}
                    {activityStatus.error? <Box sx={{textAlign:"center",p:1}}>Error</Box>:null}


                    </CardContent>
                    <CardActions>
                        <Button  component={NavLink} to="/devices"  size="small"  variant="contained">See More</Button>
                    </CardActions>
                    </Card>
            </main>
        );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);