import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import firebase from 'firebase/compat/app'
import {useRef} from 'react';
import { NavLink } from 'react-router-dom'


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';

import MenuIcon from '@mui/icons-material/Menu';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';


const drawerWidth = 240;
const MaxViewWidth = 500;

const Header = ({user}) => {    
    
    const [isWideView, setIsWideView] = useState(false);
    const [open, setOpen] = useState(false);

    
    useEffect(() => {
        const handleResize = () => {
            setIsWideView(window.innerWidth < drawerWidth+MaxViewWidth);
            setOpen(false)
        };

        // Add event listener to update the state on window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
    
   

    return (
        < >

      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          
        {isWideView ?
                    <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>:null
                    }
          
          <img src='/logo_white.png' className='HeaderLogo'/>

          <Typography variant="h6" noWrap component="div">
            Decibel Detect 
          </Typography>

          

          <Box  sx={{ flexGrow: 1 }}/>
                    

          {!isWideView ?
          <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        component={NavLink} to="/account" 
                    >
                        <PersonIcon />
                    </IconButton>
                    :null}



        </Toolbar>
      </AppBar>



            <Drawer
        open={open}
        variant={!isWideView ? "permanent": "persistent"}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
              <ListItem onClick={handleDrawerClose} component={NavLink} to="/" key="Overview" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={handleDrawerClose} component={NavLink} to="/devices" key="Devices" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Devices" />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={handleDrawerClose} component={NavLink} to="/alerts" key="Alerts" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Alerts" />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={handleDrawerClose} component={NavLink} to="/map" key="Map" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Map" />
                </ListItemButton>
              </ListItem>
          </List>
          <Divider />
          <List>
              <ListItem onClick={handleDrawerClose} component={NavLink} to="/get-devices" key="Get Devices" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Get Devices" />
                </ListItemButton>
              </ListItem>
          </List>
          <List>
              <ListItem onClick={handleDrawerClose} component={NavLink} to="/account" key="Account" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Account" />
                </ListItemButton>
              </ListItem>
          </List>

        </Box>

          

<Box className="DrawFooter">
  Made by <a href="https://spried.com/" target='_blank'>SPRIED</a>
</Box>
      </Drawer>


        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Header)