import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {Typography,  CardActionArea} from '@mui/material';
import Container from '@mui/material/Container';

import { connect } from 'react-redux'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import L from 'leaflet';
import {  Polygon } from 'react-leaflet';

import { MapContainer, Circle, CircleMarker, TileLayer, useMap,Marker,Popup } from 'react-leaflet'



import Page404 from './Page404';
import Loader from "../components/other/Loader"



const View = ({user}) => {
  
    const [outer, setOuter] = React.useState(null);
    const [deviceList, setDeviceList] = React.useState(null);
    const [CountList, setCountList] = React.useState(null);
    const [{loading,error}, setStatus] = React.useState({loading:true});
  
    //let navigate = useNavigate();
    


      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let configPosition = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'devices/position',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : "{}"
            };
            
            axios.request(configPosition).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.devices)
                {
                    const latitude  =  (Result.data.max_latitude  - Result.data.min_latitude)*0.1
                    const longitude  = (Result.data.max_longitude - Result.data.min_longitude)*0.1

                    setOuter([[Result.data.min_latitude-latitude,Result.data.min_longitude-longitude],[Result.data.max_latitude+latitude,Result.data.max_longitude+longitude]])
                    setStatus({});





                            
                        
                    let configLocation = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: baseAPIURL+'devices/locate',
                      headers: { 
                        'Authorization': idToken, 
                        'Content-Type': 'application/json'
                      },
                      data : JSON.stringify({"latitudeMin":Result.data.min_latitude-latitude,"longitudeMin":Result.data.min_longitude-longitude,"latitudeMax":Result.data.max_latitude+latitude,"longitudeMax":Result.data.max_longitude + longitude})
                    };
                    //    else if(!req.latitudeMin || !req.body.longitudeMin  || !req.body.latitudeMax  || !req.body.longitudeMax )


                    console.log(configLocation)
                    
                    axios.request(configLocation).then(Result => {
                                            
                        console.log(Result)
                        if(Result.data.error)
                        {
                            setStatus({error:true});
                        }
                        else if(Result.data)
                        {
                          setDeviceList(Result.data.devices)
                          setCountList(Result.data.count)
                            setStatus({});
                        }
                        else
                        {
                            setOuter(null)
                            setStatus({error:true});
                        }


                      
                  }).catch(err => {
                        console.error("handleSubmit")
                        console.error(err)
                        setStatus({error:true});
                  });


                  
                }
                else
                {
                    setOuter(null)
                    setStatus({error:true});
                }

  
              }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });



        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);

    





    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)




    return (
            <main>
              
              <MapContainer id="map"  bounds={outer} scrollWheelZoom={true}>
                
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />





{CountList &&  CountList.length>0 && CountList.map((row, index) => {
                  
                  return(
                    <CircleMarker center={[row.latitude,row.longitude]}>
                      <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </CircleMarker>
                  );
                  
                })}



{deviceList &&  deviceList.length>0 && deviceList.map((row, index) => {
                  return(
                          <Marker position={[row.latitude,row.longitude]}   >
                            <Popup>
                              <NavLink to={"/devices/"+row.device_uuid}>Device</NavLink>
                              {/*A pretty CSS3 popup. <br /> Easily customizable.*/}
                            </Popup>
                          </Marker>
                        )
                })}









          </MapContainer>
            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);