import React from 'react';

import { BrowserRouter, Route, Routes  } from 'react-router-dom'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Logout from './views/auth/Logout' 

import Home from './views/Home'
import Alerts from './views/Alerts'
import Devices from './views/Devices'
import Map from './views/Map'
import Account from './views/Account'
import Box from '@mui/material/Box';
import Details from './views/Details';
import Get from './views/Get';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header /> 

          <div className="MainView">
            <Routes >
              <Route path='/' element={<Home/>} />
              <Route path='/logout' element={<Logout/>} />
              
              <Route path='/alerts' element={<Alerts/>} />
              <Route path='/devices' element={<Devices/>} />
              <Route path='/devices/:id' element={<Details/>} />
              <Route path='/map' element={<Map/>} />
              <Route path='/get-devices' element={<Get/>} />
              <Route path='/account' element={<Account/>} />
                
              <Route path='*'  element={<Page404/>}  status={404}/>
            </Routes >
          </div>
          
          <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
