import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
//import { getAuth, setPersistence, signInWithRedirect, inMemoryPersistence, GoogleAuthProvider } from "firebase/auth";


const Login = () => {
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            //firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                
                //console.log(authResult)
                //console.log(redirectUrl)
                return false;
            },
            uiShown: function() {
            }
        }
    };

    //var user = firebase.auth().currentUser;
    //if (user)
    //return <Redirect to="/" />

    
  //const auth = getAuth();

        return (
            <main className='LoginView'>
                    <img src='/logo.png'/>
                    <h1 style={{fontSize:"32px"}}>Decibel Detect</h1>
                    <br/>
                    <br/>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </main>
        )
    
}
export default Login
